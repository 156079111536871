import { Component, OnInit } from '@angular/core';
import {AzureAdService} from "../../services/azure-ad.service";
import {MsalService} from "@azure/msal-angular";
import {Router} from "@angular/router";
import {AppState} from "../../../reducers/reducer";
import {Store} from "@ngrx/store";
import {getFullState, getUser} from "../../selectors/auth.selector";
import {take} from "rxjs/operators";
import {lastValueFrom} from "rxjs";
import {loginSuccess} from "../../actions/auth.actions";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  env : string = "";

  constructor(
    private readonly msalService: MsalService,
    private router: Router,
    private store: Store<AppState>,
    private readonly azureAdService: AzureAdService
  ) { }

  ngOnInit(): void {
    this.env = environment.ENVIRONMENT;

    const user = this.store.select(getUser);
    // console.log (user);
  }

  async onEnterInPortalPressed(): Promise<void>{

    const a$ =  this.store.select(getFullState).pipe(take(1));
    const data = await lastValueFrom(a$);

    if (data && data.accessToken){
      this.store.dispatch(loginSuccess(data));
    }
    else{
      this.azureAdService.getUserFromLoginPopupAndSaveJwt();
    }
  }
}
