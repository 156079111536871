import { Injectable } from '@angular/core';
import {MsalService} from "@azure/msal-angular";
import {environment} from "../../../environments/environment";
import {Store} from "@ngrx/store";
import {AppModule} from "../../app.module";
import {getToken, getUser} from "../selectors/auth.selector";
import {take, tap} from "rxjs/operators";
import {catchError, lastValueFrom, throwError} from "rxjs"
import {loginSuccess, logout} from "../actions/auth.actions";
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AzureAdService {

  signInAndUpUserFlowConfiguration: any;

  constructor(
    private readonly msalService: MsalService,
    private readonly store: Store<AppModule>
  ) {
    this.signInAndUpUserFlowConfiguration = {
      authority: environment.azureSingUpSignInEndpoint,
      scopes: ['openid', 'offline_access']
    };
  }

  getUserFromLoginPopupAndSaveJwt(): void {
    const c = this.msalService.loginPopup(this.signInAndUpUserFlowConfiguration);
    c.subscribe(async val => {
      localStorage.setItem("auth", JSON.stringify(val));
      this.store.dispatch(loginSuccess({accessToken: val.idToken, user: val.account}));
    });
  }

  async getLastToken(): Promise<any>{
    const a$ = this.store.select(getToken).pipe(take(1));
    const token = await lastValueFrom(a$);
    return jwt_decode(<string>token);
  }

  async refreshToken(forceRefresh = false) {
    const jwt =  await this.getLastToken();
    const b$ = this.store.select(getUser).pipe(take(1));
    const user = await lastValueFrom(b$);

    // console.log('acquiring Token');
    // console.log('exp')
    // console.log(jwt.exp)
    if (new Date(jwt.exp * 1000) < new Date(Date.now() - (5 * 60000)) || forceRefresh) {
      this.msalService.acquireTokenSilent({...this.signInAndUpUserFlowConfiguration, account: user}).subscribe(async val => {
        console.log('token refreshed');
        this.store.dispatch(loginSuccess({accessToken: val.idToken, user: val.account}));
      });
    }
  }

  refreshTokenNotAsync() {
    console.log("parte richiesta refresh") // Il problema è che le richieste di refresh dopo la prima partono prima ancora che la prima abbia successo
    localStorage.setItem('isRefreshing', JSON.stringify(true))

    const user = localStorage.getItem('user');
    this.msalService.acquireTokenSilent({...this.signInAndUpUserFlowConfiguration, account: user})

    return this.msalService.acquireTokenSilent({...this.signInAndUpUserFlowConfiguration, account: user}).pipe(
      tap((val) => {
        console.log("token refresh fatto")
        localStorage.setItem('isRefreshing', JSON.stringify(false));
        return this.store.dispatch(loginSuccess({accessToken: val.idToken, user: val.account}));
      }),
      catchError((e) => {
        localStorage.setItem('isRefreshing', JSON.stringify(false));
        this.store.dispatch(logout());
        return throwError(e);
      }),
    );
  }
}
