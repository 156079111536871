import {ActionReducerMap, MetaReducer} from "@ngrx/store";
import {environment} from "../../environments/environment";

export interface AppState {
}

export const reducers: ActionReducerMap<AppState> = {};


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [clearState] : [clearState];

export function clearState(reducer: any) {
  return function (state: any, action: any) {

    return reducer(state, action);
  };
}
