<div class="container">
  <mat-toolbar class="{{env}}-toolbar">
    <div class="logo-container" fxLayout="row" fxLayoutAlign="space-around center" >
      <img src="../../../../assets/img/aiwaylogo_40.png" class="logo">
      <div class="{{env}}-environment-name" >{{(env == 'local') ? 'Local' : (env == 'staging' ? 'Staging' : '')}}</div>
    </div>
    <div style="width: 100%" fxLayout="row" fxLayoutAlign="end center" >
    </div>
  </mat-toolbar>

  <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 5%">
    <button mat-raised-button (click)="onEnterInPortalPressed()">
      <mat-icon>login</mat-icon> Accedi al portale
    </button>
  </div>

</div>
