import { NgModule } from '@angular/core';
import { LoginComponent } from './components/login/login.component';
import {SharedModule} from "../shared/shared.module";
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";
import {AuthEffects} from "./effects/auth.effects";
import {authFeatureKey, reducer} from "./reducers/auth.reducer";

@NgModule({
    declarations: [
        LoginComponent
    ],
    exports: [
        LoginComponent
    ],
    imports: [
      SharedModule,
      EffectsModule.forFeature([AuthEffects]),
      StoreModule.forFeature(authFeatureKey, reducer),
    ]
})
export class AuthModule { }
