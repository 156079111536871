import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS,} from "@angular/material-moment-adapter";
import {MsalModule} from "@azure/msal-angular";
import {environment} from "../environments/environment";
import {BrowserCacheLocation, InteractionType, PublicClientApplication} from "@azure/msal-browser";
import {StoreModule} from "@ngrx/store";
import {metaReducers, reducers} from "./reducers/reducer";
import {EffectsModule} from "@ngrx/effects";
import {AuthModule} from "./auth/auth.module";
import {SharedModule} from "./shared/shared.module";
import {CanLoadRoleGuard} from "./auth/guards/can-load-role-guard.service";
import {CanLoadGuard} from "./auth/guards/can-load-guard.service";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AuthModule,
    SharedModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([]),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MsalModule.forRoot( new PublicClientApplication({ // MSAL Configuration
      auth: {
        authority: environment.azureAdCloudInstance + environment.azureAdTenantId,
        clientId: environment.azureAdClientId,
        knownAuthorities: environment.azureAdKnownAuthorities,
        redirectUri: environment.azureAdRedirectUri
      },
      cache: {
        cacheLocation : BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          loggerCallback: () => {},
          piiLoggingEnabled: false
        }
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      loginFailedRoute: '/login'
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        ['https://api.myapplication.com/users/*', ['customscope.read']],
        [environment.azureAdRedirectUri + '/about/', null]
      ])
    }),
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {strict: true}},
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    CanLoadRoleGuard,
    CanLoadGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
