import {Injectable} from "@angular/core";
import {CanLoad, Route, Router, UrlSegment} from "@angular/router";
import { Store} from "@ngrx/store";
import {AppState} from "../../reducers/reducer";
import {map, } from "rxjs";
import {getToken} from "../selectors/auth.selector";
import {take} from "rxjs/operators";
import {AzureAdService} from "../services/azure-ad.service";

@Injectable()
export class CanLoadGuard implements CanLoad {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private azureAdService: AzureAdService,
  ) { }

  canLoad(route: Route, segments: UrlSegment[]) {
    // if(localStorage.getItem('token') && localStorage.getItem('idTokenClaims')) {
    //   const claims = JSON.parse(<string>localStorage.getItem('idTokenClaims'));
    //   if(new Date(claims.exp * 1000) > new Date()) {
    //     return true;
    //   }
    // }
    // this.router.navigateByUrl('/login');
    // return false;

    return this.store.select(getToken).pipe(
      take(1),
      map(can => {
        if (!can) {
          this.router.navigateByUrl('/login');
          return false;
        }
        return true;
      })
    );
  }
}
