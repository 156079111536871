import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {BehaviorSubject, catchError, filter, from, Observable, throwError} from "rxjs";
import {AzureAdService} from "../../../auth/services/azure-ad.service";
import {Router} from "@angular/router";
import {getToken} from "../../../auth/selectors/auth.selector";
import {AppState} from "../../../reducers/reducer";
import {Store} from "@ngrx/store";
import {first, switchMap, take} from "rxjs/operators";
import {logout} from "../../../auth/actions/auth.actions";

@Injectable({
  providedIn: 'root'
})
export class ApplicationInterceptor implements HttpInterceptor{

  private backendToken$: Observable<string>;
  private refreshTokenSubject = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private azureAdService: AzureAdService,
  ) {
    this.backendToken$ = this.store.select(getToken);
  }

  private static addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.search('login') !== -1){
      return next.handle(request); // No auth
    }

    from(this.azureAdService.refreshToken());
    return this.backendToken$.pipe(
      first(), // Non voglio che al cambiare del token partano altre richieste uguali a quella ricevuta
      switchMap(token => {
        const authReq = !!token ? ApplicationInterceptor.addToken(request, token) : request;

        return next.handle(authReq);
      }),
      catchError((error: HttpErrorResponse) => {
        if(error.status === 403) {
          this.store.dispatch(logout());
        } else if(error.status === 401) {
          return this.handle401Error(request, next);
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    console.log(localStorage.getItem('isRefreshing'))

    if (localStorage.getItem('isRefreshing') != "true") {
      this.refreshTokenSubject.next(null);

      return this.azureAdService.refreshTokenNotAsync().pipe(
        switchMap((val) => {
          this.refreshTokenSubject.next(val.idToken);
          return next.handle(ApplicationInterceptor.addToken(request, val.idToken));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(ApplicationInterceptor.addToken(request, jwt));
        })
      );
    }
  }
}
