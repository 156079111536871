import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {MaterialModule} from "../material/material.module";
import {RouterModule} from "@angular/router";
import {FlexLayoutModule} from "@angular/flex-layout";
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from "@angular/material-moment-adapter";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {ApplicationInterceptor} from "./services/intercept/intercept.service";
import {ConfirmationDialogComponent} from "./components/confirmation-dialog/confirmation-dialog.component";


@NgModule({
  declarations: [ConfirmationDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FlexLayoutModule,
    HttpClientModule,
    MatDatepickerModule, MatMomentDateModule, MatSlideToggleModule,
  ],
  exports: [
    MaterialModule,
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    HttpClientModule,
  ],
  providers: [
    DatePipe,
    ApplicationInterceptor,
    {provide: HTTP_INTERCEPTORS, useClass: ApplicationInterceptor, multi: true},
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {strict: true}},
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    }
  ]
})
export class SharedModule { }
