export const environment = {
  production: false,
  ENVIRONMENT: 'staging',
  BASE_PATH: 'https://zstag-rdd-api.azurewebsites.net/api/v1',
  MAPBOX_KEY: 'pk.eyJ1IjoicnRvbm9saSIsImEiOiJja2pqenpsZHAyeDYxMnVzYzU1ZnNsMjY0In0.25N4-88Ckc1kshQRoRvxAQ',
  START_LAT: 45.9054000, // Palmanova coordinates
  START_LNG: 13.3099800,
  // AZURE
  azureAdTenantDomainName: 'rddaiway',
  azureAdClientId: 'a8ea0810-b91a-4ec3-92ee-4546d50a7934',
  azureAdCloudInstance: 'https://login.microsoftonline.com/',
  azureAdTenantId: "b64b57c6-2138-4b6d-8717-1ed6bc1afc29",
  azureAdRedirectUri: 'https://zstag-rddmonitor.azurewebsites.net',
  azureAdKnownAuthorities: [`https://rddaiway.b2clogin.com/`],
  azureSingUpSignInEndpoint: 'https://rddaiway.b2clogin.com/rddaiway.onmicrosoft.com/B2C_1_SignInAiWay/',
  backendBasePath : 'https://zstag-rdd-api.azurewebsites.net/',
  azureAdCustomParametersClientId: '37a70855-2f51-4522-a48a-0f05684b29a8'
};
