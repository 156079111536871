import { Injectable } from "@angular/core";
import {CanLoad, Route, UrlSegment} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers/reducer";
import {map, Observable} from "rxjs";
import {getRoles} from "../selectors/auth.selector";

@Injectable()
export class CanLoadRoleGuard implements CanLoad {
  constructor(
    private store: Store<AppState>,
  ) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean>|Promise<boolean>|boolean {
    return this.store
      .pipe(
        select(getRoles),
        map(roles => {
          const requiredRoles = route.data?.roles as Array<string>;
          if (requiredRoles) {
            if (roles) {
              if (roles.includes('ADMIN')) {
                // ADMIN can do anything
                return true;
              }
              for (const requiredRole of requiredRoles) {
                if (roles.includes(requiredRole)) {
                  return true;
                }
              }
              // no role match
              return false;
            } else {
              // no roles at all
              return false;
            }
          } else {
            return true;
          }
        })
      );
  }
}
