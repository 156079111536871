import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromAuth from '../reducers/auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.State>(
  fromAuth.authFeatureKey
);

export const getToken = createSelector(
  selectAuthState,
  auth => auth.accessToken ? auth.accessToken : ''
);

export const getUser = createSelector(
  selectAuthState,
  auth => auth.user ? auth.user : ''
);

export const getRoles = createSelector(
  selectAuthState,
  auth => auth.user?.idTokenClaims?.extension_Roles?.split(",")
);

export const getFullState = createSelector(
  selectAuthState,
  auth => auth ? auth : ''
);
