import {createReducer, on} from "@ngrx/store";
import * as AuthActions from '../actions/auth.actions';

export const authFeatureKey = 'auth';

export interface State {
  accessToken: string | '';
  user: any | '';
  error: string | '';
}

export const initialState: State = {
  accessToken: localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token') || '') : undefined,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : undefined,
  error: '',
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.loginSuccess, (state, action) =>
    ( {...state, accessToken: action.accessToken, user: action.user})),
);

