import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers/reducer";
import {loginSuccess, logout, saveToken} from "../actions/auth.actions";
import {tap} from "rxjs/operators";
import {UserRoles} from "../../shared/enum/user-roles.enum";


@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<AppState>,
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess),
      tap(async action => {
          this.store.dispatch(saveToken(action));
          if (action.user.idTokenClaims?.hasOwnProperty('extension_Roles') &&
            action.user.idTokenClaims.extension_Roles === UserRoles.ADMIN){
            await this.router.navigate(['admin']);
          }
          else {
            await this.router.navigate(['home']);
          }
        }
      )
    ), {dispatch: false}
  );

  storeTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveToken),
      tap(action => {
        localStorage.setItem('token', JSON.stringify(action.accessToken));
        localStorage.setItem('user', JSON.stringify(action.user));
      })
    ), {dispatch: false}
  );

  logoutSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(logout),
      tap(async () => {
        await this.router.navigate(['login']);
        localStorage.clear();
        location.reload();
      })
    ), {dispatch: false}
  );
}
